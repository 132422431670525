import React, { Fragment } from "react"
import { Link, graphql } from "gatsby"
import { motion } from "framer-motion"
// import Seo from "../components/seo"
import Header from "../components/header"
import Container from "../components/container"

//TO-DO If is a custom post use a custom layout if not use the default

const Home = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description
  const posts = data.allMdx.nodes
  console.log(posts)
  return (
    <motion.div exit={{ opacity: 0 }}>
      {/* <Seo
        title={siteTitle}
        description={siteDescription}
        script={"../scripts/multi-step-form.js"}
      /> */}
      <Header />
      {/*<Container>
        <div className="prose mt-24">
          <h1>Headling</h1>
          <p>
            <Link to="/blog">Visit the blog</Link>
          </p>
        </div>
      </Container>*/}
    </motion.div>
  )
}

export default Home

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        slug
        frontmatter {
          date(formatString: "Do MMMM YYYY")
          title
          description
        }
      }
    }
  }
`
